import React from "react"
import Layout from "components/layout"
import { Link } from "gatsby"

import { Typography } from "components/typography"
import * as Clickable from "components/clickable"

import { CreditCardOffer as Offer } from "components/content/offer"
import CreditCardOffer from "models/offer-models/CreditCardOffer.js"
import { LazyLoadImage } from "react-lazy-load-image-component"
import * as style from "./style.module.scss"
import { cardImage, providerImage } from "components/survey/utils.js"
import { CardValues } from "components/content/product-values-row"
import { CreditCardDisclaimer } from "components/content/content-disclaimer"
import { useMediaQuery } from "@mui/material"

const Direct = () => {
  const offer = {
    productId: 40000100,
    providerId: 400,
    offerId: "0040000100_2022-02-24_002",
    dateExpire: "2022-06-30",
    landingPageUrl: "https://a.mozo.com.au/record_gts?click_type=gts&originaldc=stayorgo.com.au&partial=mpp&path=none&product_id=617&product_type=CreditCard&provider_id=131&the_url=https%3A%2F%2Fad.doubleclick.net%2Fddm%2Fclk%2F505081219%3B290218140%3Bt%3Fhttps%3A%2F%2Fimp.i155730.net%2Fc%2F1191226%2F1138567%2F11494",
    providerDisclaimer: "Receive up to $400 Cashback when you take out a new Low Rate Credit Card. Get $100 for every $1,000 spent on eligible purchases for the first 4 statement periods from approval.",
    active: "Y",
    offerHeadline: null,
    afYear1: null,
    cashBack: 400,
    cashBackDescription: "cash back",
    bonusPointsY1: null,
    bonusPointsY2: null,
    bonusPointsY3: null,
    btRate: 0.0699,
    btMonths: 12,
    btRevert: 0.2149,
    btFee: 0.01,
    introPurchaseRate: null,
    introPurchaseMonths: null,
    paymentPlanRate: null,
    paymentPlanMonths: null,
    paymentPlanFee: null,
  }
  const product = {
    id: 40000100,
    name: "Westpac Low Rate",
    nameLong: "Westpac Low Rate",
    providerId: 400,
    providerName: "Westpac",
    forSale: "Y",
    tmdUrl: "https://www.westpac.com.au/tmd/",
    autoDescription: "The Westpac Low Rate Credit Card offers $400 cash back. Ongoing benefits include a low ongoing interest rate.",
    urlSlug: "westpac-low-rate-credit-card",
    description: "Our Low Rate Card provides a low interest purchase rate plus a low annual card fee to help trim your credit card costs.",
    productCategoryId: "CREDIT_CARD",
    offers: {},
    cardHeading: "Westpac Low Rate Credit Card",
    rewardProgramId: 0,
    rewardProgramName: "No Reward Points",
    rewardProgramPointsName: null,
    cardImages: 2,
    schemeType: 3,
    schemeTypeFb: 2,
    interchangeCategory: "Classic",
    accountFee: 59,
    afFreq: "P1Y",
    totalAfPrimary: 59,
    purchaseRate: 0.1374,
    directRewardId: null,
    cashRate: 0.2149,
    freeDays: 55,
    rewardsFee: null,
    rfFreq: null,
    afSpendWaiver: null,
    afswFreq: null,
    achFee: null,
    achfFreq: null,
    achRewardsFee: null,
    achrfFreq: null,
    totalAfAch: 0,
    achMax: 1,
    fxFee: 0.03,
    tier1EarnRate: null,
    tier1Cap: null,
    tier2EarnRate: null,
    tier2Cap: null,
    annualCap: null,
    fxEarnRate: "None",
    minLimit: 500,
    maxLimit: 50000,
    annualVoucher: null,
    voucherDescription: null,
    osTravelInsurance: "N",
    loungePassNum: null,
    loungePassDescription: null,
    insurance: null,
    annualFee: 59,
    pointsPerSpend: 0,
    topPPS: "100",
    topFee: "25",
    topRate: "25",
  }
  const isMobile = !useMediaQuery("(min-width:768px)")
  const largeCardImage = true
  const useAsterisk = true
  const learnMore = "/westpac-low-rate-credit-card"
  const OfferComponent = () => {
    if (isMobile) {
      return <Offer offerJson={offer as CreditCardOffer} providerName={product.providerName} productName={product.nameLong} isTablet={isMobile} hideButton />
    } else {
      return <Offer offerJson={offer as CreditCardOffer} providerName={product.providerName} productName={product.nameLong} isTablet={isMobile} learnMore={learnMore} />
    }
  }

  const GetStartedButton = () => {
    return (
      <Clickable.Text comp={1} component={Link} to={"/credit-cards/instructions/"} className="landing-page-button">
        Get Started
      </Clickable.Text>
    )
  }

  const ProductComponent = () => {
    if (isMobile) {
      return (
        <div>
          <div style={{ display: "flex", position: "relative", marginBottom: "40px" }}>
            <div style={{ width: "33%" }}>
              <LazyLoadImage src={cardImage(product.id)} title={product.cardHeading} alt={product.cardHeading} style={{ width: "100%" }} />
            </div>
            <div style={{ width: "33%" }}></div>
            <div style={{ width: "33%" }}>
              <LazyLoadImage src={providerImage(product.providerId)} alt={product.providerName} title={product.providerName} style={{ position: "absolute", right: "0", height: "38px", width: "56px" }} />
            </div>
          </div>
          <div style={{ width: "100%", height: "88px", marginBottom: "40px" }}>
            <CardValues product={product} mobileProduct useAsterisk={useAsterisk} />
          </div>
          <Clickable.Text className={style.button} component={Link} to={learnMore} secondary>
            Learn More
          </Clickable.Text>
        </div>
      )
    } else {
      return (
        <div className={style.upperContainer}>
          <div className={style.cardRow} style={{ marginTop: "30px", marginBottom: largeCardImage ? "54px" : "40px" }}>
            <div className={largeCardImage ? style.cardImageLarge : style.cardImage}>
              <LazyLoadImage src={cardImage(product.id)} title={product.cardHeading} alt={product.cardHeading} className={style.cardImageImg} />
            </div>
            <div className="show-for-desktop" style={{ width: "8.3%" }}></div>
            <div className={style.cardValues}>
              <CardValues product={product} useAsterisk={useAsterisk} />
            </div>
            <div className="show-for-desktop" style={{ width: "16.7%" }}>
              <LazyLoadImage src={providerImage(product.providerId)} alt={product.providerName} title={product.providerName} style={{ height: "53px", width: "77px", marginLeft: "auto", marginRight: "auto", display: "block", marginTop: "17.5px" }} />
            </div>
          </div>
        </div>
      )
    }
  }

  return (
    <Layout>
      <div className="container-center" style={{ marginTop: "30px" }}>
        <div className="home-container">
          <Typography.H1>Great deal? {"\n"} See if there's better.</Typography.H1>
          <div style={{ display: "flex", justifyContent: "space-between", marginTop: "60px" }}>
            <div>
              <Typography.P comp="large">
                See if there is a better deal in <span style={{ fontFamily: "Arboria-Bold, sans-serif", color: "#00afc5" }}>60 seconds</span>
              </Typography.P>
            </div>
            {!isMobile ? <GetStartedButton /> : null}
          </div>
          {isMobile ? (
            <div style={{ marginTop: "30px" }}>
              <GetStartedButton />
            </div>
          ) : null}
          <div style={{ marginBottom: "200px" }}>
            <p style={{ fontFamily: "Arboria-Bold, sans-serif", fontSize: "18px", marginTop: "50px", marginBottom: "30px" }}>Westpac Low Rate Credit Card</p>
            <OfferComponent />
            <ProductComponent />
          </div>
          <CreditCardDisclaimer isDisclaimerOpen isProvider />
        </div>
      </div>
    </Layout>
  )
}

export default Direct
