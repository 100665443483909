// extracted by mini-css-extract-plugin
export var button = "style-module--button--07c20";
export var cardImage = "style-module--card-image--878a5";
export var cardImageImg = "style-module--card-image-img--866ed";
export var cardImageLarge = "style-module--card-image-large--94b44";
export var cardImageMobile = "style-module--card-image-mobile--f1ecd";
export var cardRow = "style-module--card-row--adb31";
export var cardRowMobile = "style-module--card-row-mobile--96af1";
export var cardValues = "style-module--card-values--a2a0d";
export var cardValuesMobile = "style-module--card-values-mobile--ebde0";
export var offersList = "style-module--offers-list--b13cb";
export var offersListMobile = "style-module--offers-list-mobile--219e1";
export var subHeading = "style-module--sub-heading--fce57";
export var upperContainer = "style-module--upper-container--bb396";